import { useEffect, useRef, useCallback, useMemo, useState } from "react";
import { Card, CardMedia, CardActionArea, makeStyles } from "@material-ui/core";
import { Link, Switch, Route, useLocation, useHistory } from "react-router-dom";
import { FixedSizeGrid as Grid } from "react-window";
import ImageGallery from "react-image-gallery";
import {
	useWindowSize,
	useAwaitableGoBack,
	useAwaitableBackButton,
} from "../Util";
import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme) => ({
	card: {
		margin: "10px",
	},
	media: {
		height: 180,
	},
}));

function useQuery(searchParam: string) {
	const query = new URLSearchParams(useLocation().search);
	return query.get(searchParam);
}

function useIndexQuery() {
	return Number(useQuery("index"));
}

function useReplaceIndex(path?: string) {
	const { pathname } = useLocation();
	const history = useHistory();
	return useCallback(
		(index: number) => {
			history.replace(`${path || pathname}?index=${index}`);
		},
		[history, pathname, path]
	);
}

function useGoBackToIndex(path?: string) {
	const { pathname } = useLocation();
	const history = useHistory();
	const awaitableGoBack = useAwaitableGoBack();
	return useCallback(
		async (index: number) => {
			await awaitableGoBack();
			history.replace(`${path || pathname}?index=${index}`);
		},
		[history, pathname, path, awaitableGoBack]
	);
}

function indexToRowCol(index: number, numberOfColumns: number) {
	const row = Math.floor(index / numberOfColumns);
	const column = index % numberOfColumns;
	return { row, column };
}

function rowColToIndex(row: number, column: number, numberOfColumns: number) {
	return row * numberOfColumns + column;
}

const Gallery = ({
	path,
	filePath,
	filenames,
}: {
	path: string;
	filePath: string;
	filenames: string[];
}) => {
	const history = useHistory();
	const [entryPoint, setEntryPoint] = useState(true);

	useEffect(() => {
		return history.listen((location, action) => {
			if (action === "PUSH") {
				setEntryPoint(false);
			}
		});
	});

	return (
		<Switch>
			<Route path={`${path}/fullsize`}>
				<Fullsize
					entryPoint={entryPoint}
					path={path}
					filePath={filePath}
					filenames={filenames}
				/>
			</Route>
			<Route path={`${path}`}>
				<PhotoGrid path={path} filePath={filePath} filenames={filenames} />
			</Route>
		</Switch>
	);
};

const PhotoGrid = ({
	path,
	filePath,
	filenames,
}: {
	path: string;
	filePath: string;
	filenames: string[];
}) => {
	const classes = useStyles();
	const cellWidth = 320;
	const cellHeight = 180;
	const numberOfColumns = useRef<number>(0);
	const numberOfRows = useRef<number>(0);
	const { height, width } = useWindowSize();
	numberOfColumns.current = Math.floor(width / cellWidth);
	numberOfRows.current = Math.ceil(filenames.length / numberOfColumns.current);

	const index = useIndexQuery();
	const { row, column } = indexToRowCol(index, numberOfColumns.current);

	const grid = useRef<Grid>(null);

	useEffect(() => {
		grid.current?.scrollToItem({
			rowIndex: row,
			columnIndex: column,
			align: "smart",
		});
	}, [row, column, grid]);

	const Cell = ({
		columnIndex,
		rowIndex,
		style,
	}: {
		columnIndex: number;
		rowIndex: number;
		style: any;
	}) => {
		const fileIndex = rowColToIndex(
			rowIndex,
			columnIndex,
			numberOfColumns.current
		);
		const filename = filenames[fileIndex];

		return (
			<div style={style}>
				<Card className={classes.card}>
					<CardActionArea
						component={Link}
						to={`${path}/fullsize?index=${fileIndex}`}
					>
						<CardMedia
							className={classes.media}
							image={`${filePath}/${filename}?nf_resize=fit&w=400`}
						/>
					</CardActionArea>
				</Card>
			</div>
		);
	};

	return (
		<Grid
			ref={grid}
			columnCount={numberOfColumns.current}
			columnWidth={Math.floor((width - 20) / numberOfColumns.current)}
			height={height}
			rowCount={numberOfRows.current}
			rowHeight={cellHeight + 25}
			width={width}
		>
			{Cell}
		</Grid>
	);
};

const Fullsize = ({
	entryPoint,
	path,
	filePath,
	filenames,
}: {
	entryPoint: boolean;
	path: string;
	filePath: string;
	filenames: string[];
}) => {
	const index = useIndexQuery();
	const gallery = useRef<ImageGallery>(null);

	useEffect(() => {
		gallery.current?.fullScreen();
	}, [gallery]);

	const filePaths = useMemo(
		() =>
			filenames.map((filename) => ({
				original: `${filePath}/${filename}?nf_resize=fit&w=2000`,
				thumbnail: `${filePath}/${filename}?nf_resize=fit&w=200`,
			})),
		[filePath, filenames]
	);

	const goBackToIndex = useGoBackToIndex(`${path}`);
	const replaceIndexGallery = useReplaceIndex(`${path}`);

	const history = useHistory();

	const awaitableBackButton = useAwaitableBackButton();

	useEffect(() => {
		return history.listen(async (location, action) => {
			if (action === "POP") {
				await awaitableBackButton();
				replaceIndexGallery(index);
			}
		});
	}, [history, index, goBackToIndex, awaitableBackButton, replaceIndexGallery]);

	const onClose = () => {
		const index = gallery.current?.getCurrentIndex();
		if (index) {
			if (!entryPoint) {
				goBackToIndex(index);
			} else {
				replaceIndexGallery(index);
			}
		}
	};

	const onScreenChange = (open: boolean) => {
		if (!open) {
			onClose();
		}
	};

	const replaceIndex = useReplaceIndex();

	return (
		<ImageGallery
			lazyLoad
			ref={gallery}
			items={filePaths}
			startIndex={index}
			useBrowserFullscreen={false}
			//@ts-ignore
			onScreenChange={onScreenChange}
			onSlide={replaceIndex}
		/>
	);
};

export default Gallery;

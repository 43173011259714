import { filenames } from "./Filenames.js";
import Gallery from "../Gallery";

const Wedding = () => {
	return (
		<Gallery path="/wedding" filePath="/photos/wedding" filenames={filenames} />
	);
};

export default Wedding;

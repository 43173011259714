import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./Landing";
import Wedding from "./Wedding";
import { Party } from "./Party";

function App() {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Router>
        <Switch>
          <Route path="/wedding">
            <Wedding />
          </Route>
          <Route path="/party">
            <Party />
          </Route>
          <Route>
            <Landing />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

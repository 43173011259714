import React from "react";

export const Party = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f8d7a5",
        color: "#50394c",
        overflow: "scroll",
        padding: "20px",
        boxSizing: "border-box",
      }}
    >
      <h2>
        It's Spring! <br /> It's Trevor's birthday! <br /> Lets grill!
      </h2>
      <h3>
        You're invited to a fun-filled evening of food, drinks, and s'mores
        around the fire!
      </h3>
      <p>
        <strong>Date:</strong> Saturday, May 6th 5:00pm - Late
      </p>
      <p>
        <strong>Location:</strong> 30420 Jacobson Ln. Fort Bragg
      </p>
      <p>
        <strong>Directions:</strong> Google maps doesn't know how to get here.
        Instead navigate to{" "}
        <a
          href="https://goo.gl/maps/RFjpJ9dW1RtDbPBL6"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        <br />
        <p>
          <strong>Then</strong> continue along Jacobson Ln until you see 30420,
          its near the end on the left.
        </p>
      </p>
      <p>Please let us know if you can make it</p>
      <p>Bring a lawn chair if you have one!</p>
      <p>Bring something to share if you like</p>
      <img
        src="/web-photos/bonfire-ai.png"
        max-width="100%"
        alt="Ai generated bonfire"
        style={{ maxWidth: "500px", width: "100vw", margin: "0px -20px -20px" }}
      />
    </div>
  );
};

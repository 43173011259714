import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "400px",
    height: "400px",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
}));

const Landing = () => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        rowGap: "36px",
        columnGap: "36px",
        padding: "36px",
        flexWrap: "wrap",
        backgroundColor: "#f8d7a5",
        color: "#50394c",
        height: "100vh",
      }}
    >
      <Card className={classes.card}>
        <CardActionArea component={Link} to="/wedding">
          <CardHeader title="Wedding Photos" />
          <CardMedia
            className={classes.media}
            image="/photos/wedding/color selection-152.jpg?nf_resize=fit&w=400"
          />
          <CardContent>
            <Typography>
              Covid didn't stop up from having a beautiful wedding on the
              Mendocino Headlands
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card className={classes.card}>
        <CardActionArea component={Link} to="/party">
          <CardHeader title=" Birthday Bonfire and Grill" />
          <CardMedia
            className={classes.media}
            image="/web-photos/bonfire-ai.png"
          />
          <CardContent>
            <Typography>
              Join us for an evening of food, fire and company.
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default Landing;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const rootDiv = document.getElementById("root");

const rootStyle = {
	height: "100%",
	overflow: "hidden",
};

Object.assign(rootDiv.style, rootStyle);

ReactDOM.render(
	<React.StrictMode>
		<App style={rootStyle} />
	</React.StrictMode>,
	rootDiv
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

export function useWindowSize() {
	const [{ width, height }, setSize] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	const resize = useCallback(
		() =>
			setSize({
				height: window.innerHeight,
				width: window.innerWidth,
			}),
		[]
	);

	useEffect(() => {
		window.addEventListener("resize", resize);
		return () => {
			window.removeEventListener("resize", resize);
		};
	}, [resize]);
	return { width, height };
}

export function useAwaitableGoBack() {
	const { pathname } = useLocation();
	const history = useHistory();

	return useCallback(
		() =>
			new Promise<void>((resolve, reject) => {
				const checkGoneBack = () => {
					if (pathname === history.location.pathname) {
						requestAnimationFrame(checkGoneBack);
					} else {
						resolve();
					}
				};

				history.goBack();
				requestAnimationFrame(checkGoneBack);
			}),
		[history, pathname]
	);
}

export function useAwaitableBackButton() {
	const { pathname } = useLocation();
	const history = useHistory();

	return useCallback(
		() =>
			new Promise<void>((resolve, reject) => {
				const checkGoneBack = () => {
					if (pathname === history.location.pathname) {
						requestAnimationFrame(checkGoneBack);
					} else {
						resolve();
					}
				};

				requestAnimationFrame(checkGoneBack);
			}),
		[history, pathname]
	);
}
